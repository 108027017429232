import { css } from "@emotion/react";

import variables from "./variables";

const reactModal = css`
  .ReactModalPortal {
    z-index: 12; // Above header
    position: relative;
  }
  .ReactModal__Body--open,
  .body-has-overlay {
    overflow: hidden;
  }
  .ReactModal__Content {
    opacity: 0;
    transition: opacity ${variables.transitionSpeed} ease;
  }

  .ReactModal__Content--after-open {
    opacity: 1;
  }

  .ReactModal__Content--before-close {
    opacity: 0;
  }
`;

export default reactModal;
