import { css } from "@emotion/react";
import variables from "./variables";

const reactCollapse = css`
  .ReactCollapse--collapse {
    transition: height ${variables.transitionSpeed};
  }
`;

export default reactCollapse;
