import { css } from "@emotion/react";

import variables from "./variables";
import toastify from "./toastify";
import reactModal from "./react-modal";
import rcTooltip from "./rc-tooltip";
import reactCollapse from "./react-collapse";
import reactDatePicker from "./react-datepicker";
import reactMasonry from "./react-masonry";
import quillMention from "./quill-mention";

const baseStyles = css`
  * {
    box-sizing: border-box;
  }
  body {
    min-height: 100vh;
    overscroll-behavior-y: none;
    padding: 0;
    margin: 0;
    font-family: ${variables.baseFontFamily};
    font-weight: ${variables.fontWeightRegular};
    color: ${variables.colorText};
    text-align: left;
  }
  [tabindex="-1"]:focus {
    outline: none !important;
  }
  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    line-height: 1;
  }
  p {
    margin: 0;
    line-height: 1;
  }
  ol,
  ul,
  dl {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  img {
    vertical-align: middle;
    border-style: none;
  }
  a {
    text-decoration: none;
    color: ${variables.colorMainBlue};
    font-weight: ${variables.fontWeightRegular};

    :hover {
      text-decoration: underline;
    }
  }
  input,
  button,
  select,
  optgroup,
  textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }
  textarea,
  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="button"],
  input[type="submit"] {
    -webkit-appearance: none;
  }
  textarea,
  input,
  input[type="text"],
  input[type="email"],
  input[type="password"] {
    color: ${variables.colorText};
    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${variables.colorSecondaryGray};
      opacity: 1; /* Firefox */
    }
  }
  textarea {
    overflow: auto;
    resize: none;
  }
  button {
    cursor: pointer;
    background: transparent;
    line-height: 1;
    box-shadow: none;
    border: 0;
    border-radius: 0;
    padding: 0;
    margin: 0;
    :active {
      outline: none;
      box-shadow: none;
    }
  }
  button,
  input {
    overflow: visible;
  }
  button,
  select {
    text-transform: none;
  }

  pre {
    white-space: pre-wrap; /* Since CSS 2.1 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
  }

  .js-focus-visible button:focus:not(.focus-visible),
  .js-focus-visible select:focus:not(.focus-visible),
  .js-focus-visible a:focus:not(.focus-visible),
  .js-focus-visible [role="button"]:focus:not(.focus-visible) {
    outline: none;
  }

  ${rcTooltip};
  ${toastify};
`;

const globalStyles = css`
  #__next {
    min-height: 100vh;
  }

  ${baseStyles};
  ${quillMention};
  ${reactModal};
  ${reactCollapse};
  ${reactDatePicker};
  ${reactMasonry};
`;

export default globalStyles;
export { baseStyles };
