import { css } from "@emotion/react";
import variables from "./variables";
import pxToRem from "./px-to-rem";
import { touchScrollY } from "./touch-scroll";
import { bodySmall } from "./typography";

const quillMention = css`
  .ql-mention-list-container {
    z-index: 14;
    ${touchScrollY};
    max-height: ${pxToRem(140)};
    background: ${variables.colorWhite};
    border-radius: ${variables.roundness2};
    border: ${pxToRem(1)} solid ${variables.colorSecondaryGray};
    box-shadow: 0 ${pxToRem(5)} ${pxToRem(10)} rgba(0, 0, 0, 0.15);
  }

  .ql-mention-list-item {
    ${bodySmall};
    padding: ${pxToRem(6)} ${pxToRem(10)};
    cursor: pointer;

    :hover,
    :focus,
    :active,
    &.selected {
      background: ${variables.colorBackground};
    }
  }

  .ql-mention-list-item--member {
    ${bodySmall};
    display: grid;
    grid-template-columns: auto;
    grid-auto-flow: column;
    align-items: center;
    grid-gap: ${variables.spaceMicro};
    justify-content: start;
  }

  .ql-mention-list-item--name-line {
    display: grid;
    grid-template-columns: auto;
    grid-auto-flow: column;
    align-items: center;
    grid-gap: ${variables.spaceMicro};
    justify-content: start;
  }

  .ql-mention-list-item--name {
    color: ${variables.colorText};
    font-weight: ${variables.fontWeightSemiBold};
    text-overflow: ellipsis;
  }

  .ql-mention-list-item--email {
    text-overflow: ellipsis;
    color: ${variables.colorPrimaryGray};
  }

  .ql-mention-list-item--avatar {
    width: ${pxToRem(24)};
    height: ${pxToRem(24)};
    border-radius: 50%;
  }
`;

export default quillMention;
