import { css } from "@emotion/react";

import pxToRem from "./px-to-rem";
import variables from "./variables";

// Base

// https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
export const wordWrap = css`
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: none;
`;

export const hiddenText = css`
  display: block;
  text-indent: -9999px;
  white-space: nowrap;
  overflow: hidden;
`;

export const truncate = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const h1Regular = css`
  font-size: ${pxToRem(24)};
  line-height: ${32 / 24};
  font-weight: ${variables.fontWeightRegular};

  @media (min-width: ${variables.mobileTabletMaxWidth}) {
    font-size: ${pxToRem(28)};
    line-height: ${36 / 28};
  }
`;

export const h1Bold = css`
  ${h1Regular};
  font-weight: ${variables.fontWeightMedium};
`;

export const h2Regular = css`
  font-size: ${pxToRem(18)};
  line-height: ${24 / 18};
  font-weight: ${variables.fontWeightRegular};

  @media (min-width: ${variables.mobileTabletMaxWidth}) {
    font-size: ${pxToRem(24)};
    line-height: ${32 / 24};
  }
`;

export const h2Bold = css`
  ${h2Regular};
  font-weight: ${variables.fontWeightMedium};
`;

export const h3Regular = css`
  font-size: ${pxToRem(16)};
  line-height: ${18 / 16};
  font-weight: ${variables.fontWeightRegular};

  @media (min-width: ${variables.mobileTabletMaxWidth}) {
    font-size: ${pxToRem(18)};
    line-height: ${24 / 18};
  }
`;

export const h3Bold = css`
  ${h3Regular};
  font-weight: ${variables.fontWeightMedium};
`;

export const h4Regular = css`
  font-size: ${pxToRem(16)};
  line-height: ${18 / 16};
  font-weight: ${variables.fontWeightRegular};
`;

export const h4Bold = css`
  ${h4Regular};
  font-weight: ${variables.fontWeightMedium};
`;

export const h5Regular = css`
  font-size: ${pxToRem(14)};
  line-height: ${22 / 14};
  font-weight: ${variables.fontWeightRegular};
`;

export const h5Bold = css`
  ${h5Regular};
  font-weight: ${variables.fontWeightMedium};
`;

export const h6Regular = css`
  font-size: ${pxToRem(12)};
  line-height: ${16 / 12};
  font-weight: ${variables.fontWeightRegular};
`;

export const h6Bold = css`
  ${h6Regular};
  font-weight: ${variables.fontWeightMedium};
`;

export const cta = css`
  font-size: ${pxToRem(14)};
  line-height: ${18 / 14};
  font-weight: ${variables.fontWeightBold};
`;

export const mainHeader = css`
  font-size: ${pxToRem(22)};
  line-height: ${30 / 22};

  @media (min-width: ${variables.mobileTabletMaxWidth}) {
    font-size: ${pxToRem(24)};
    line-height: ${30 / 24};
  }
`;

export const conversation = css`
  ${h3Bold};
  color: ${variables.colorText};
`;

export const subtitle = css`
  ${h5Regular};
  color: ${variables.colorText};
`;

export const subtitleBold = css`
  ${h5Bold};
  color: ${variables.colorText};
`;

export const bodyNormal = css`
  font-size: ${pxToRem(14)};
  line-height: ${22 / 14};
`;

export const bodySmall = css`
  font-size: ${pxToRem(12)};
  line-height: ${16 / 12};
`;

export const paragraph = css`
  ${bodyNormal};
`;

export const textLink = css`
  ${bodyNormal};
`;

export const capture = css`
  font-size: ${pxToRem(10)};
  line-height: ${14 / 10};
  font-weight: ${variables.fontWeightMedium};
`;

export const cardsTitle = css`
  ${h4Bold};
  color: ${variables.colorSecondaryGray};
`;

export const code = css`
  ${bodyNormal};
  font-family: Courier, Lucida Console, Monaco;
  color: ${variables.colorText};
`;
