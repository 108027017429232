import { css } from "@emotion/react";

import pxToRem from "./px-to-rem";
import variables from "./variables";

const reactDatePicker = css`
  .react-datepicker {
    color: ${variables.colorPrimaryGray};
    border: ${pxToRem(1)} solid ${variables.colorSecondaryGray};
  }

  .react-datepicker-wrapper {
    display: block;
  }

  .react-datepicker__header {
    background: ${variables.colorWhite};
    border-bottom: 0;
  }

  .react-datepicker__input-container {
    display: block;
  }

  .react-datepicker__navigation--previous {
    border-right-color: ${variables.colorSecondaryGray};
  }

  .react-datepicker__navigation--next {
    border-left-color: ${variables.colorSecondaryGray};
  }

  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::before {
    border-bottom-color: ${variables.colorWhite};
  }

  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle::before {
    border-bottom-color: ${variables.colorSecondaryGray};
  }

  .react-datepicker__day-name {
    font-weight: ${variables.fontWeightMedium};
    color: ${variables.colorPrimaryGray};
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: ${variables.colorPrimaryGray};
  }

  .react-datepicker__day--selected {
    color: ${variables.colorWhite};
  }

  .react-datepicker__month {
    margin: 0 0.4rem 0.4rem;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    font-weight: ${variables.fontWeightMedium};
    color: ${variables.colorPrimaryGray};
  }

  .react-datepicker__input-container input {
    appearance: none;
    width: 100%;
    height: ${pxToRem(44)};
    padding: ${variables.spaceTiny} ${variables.spaceSmall};
    border: ${pxToRem(1)} solid ${variables.colorSecondaryGray};
    border-radius: ${variables.roundness1};
    font-family: ${variables.baseFontFamily};
    font-size: ${pxToRem(14)};
    outline: none;
    text-overflow: ellipsis;
    transition: border-color ${variables.transitionSpeed} ease;

    &.invalid {
      border: ${pxToRem(1)} solid ${variables.colorError};
    }

    &:focus {
      border-color: ${variables.colorHighlightBlue};
    }

    &:disabled {
      border-color: ${variables.colorSecondaryGray};
      cursor: not-allowed;
    }
  }

  .react-datepicker__day--disabled {
    color: ${variables.colorSecondaryGray};
  }
`;

export default reactDatePicker;
