import pxToRem from "./px-to-rem";

const variables = {
  baseFontFamily: "system-ui, -apple-system, sans-serif",
  marketingHeaderFontFamily:
    "EuclidCircularA, system-ui, -apple-system, 'Segoe UI', Roboto, sans-serif",
  marketingBaseFontFamily:
    "'Noto Sans', system-ui, -apple-system, 'Segoe UI', Roboto, sans-serif",
  fontWeightLight: "300",
  fontWeightRegular: "400",
  fontWeightMedium: "500",
  fontWeightSemiBold: "600",
  fontWeightBold: "700",
  colorWhite: "#ffffff",
  colorBlack: "#000000",
  colorRed: "#ff0000",
  colorMarkerRed: "#FF2600",
  colorMarkerGreen: "#00E600",
  colorMarkerYellow: "#FFF500",
  colorMarkerBlue: "#1A8EFF",
  colorMarkerPink: "#FF2F6C",
  colorBrandYellow: "#FFBE00",
  colorAccentBlue: "#5CABE5",
  colorDarkAccentBlue: "#007AB1",
  colorAccentOpaqueBlue: "#EEF6FF",
  colorYellow: "#FED851",
  colorAccentYellow: "#FED581",
  colorAccentYellowBackground: "rgba(254, 213, 129, 0.2)",
  colorHighlightYellow: "#FAFFF7",
  colorMainBlue: "#00507D",
  colorMainBlueDark: "#003D61",
  colorMainGreen: "#17B97C",
  colorSelectedGreen: "#22795F",
  colorNotificationBadge: "#FF596D",
  colorHighlightBlue: "#19C2EF",
  colorHighlightGreen: "#EBFCF6",
  colorPrimaryGray: "#576F86",
  colorSecondaryGray: "#94AABF",
  colorLightGray: "#D9D9D9",
  colorGhostWhite: "#F8F9FD",
  colorContainerGray: "#E5EEF7",
  colorDarkContainerGray: "#CFDFEC",
  colorLightContainerGray: "#FAFAFA",
  colorBackground: "#F7FBFF",
  colorDarkGrayBackground: "#555555",
  colorSeparator: "#CFDFEC",
  colorText: "#2D3941",
  colorError: "#FF596D",
  colorErrorBackground: "#FEEFF1",
  colorOrange: "#FFB627",
  colorStatusNew: "#ffbe00",
  colorStatusPlanned: "#19C2EF",
  colorStatusReviewed: "#FF596D",
  colorStatusImplemented: "#17B97C",
  colorStatusArchived: "#94AABF",
  colorWarning: "#E46455",
  colorWarningBackground: "#FFFAE6",
  colorWarningBorder: "#FFF2C7",
  colorBrownAvatar: "#a5636b",
  colorPurpleAvatar: "#b730e3",
  colorOrangeAvatar: "#ff6d3c",
  colorGreyAvatar: "#bfbbbe",
  colorPinkAvatar: "#f67ae6",
  colorCyanAvatar: "#7bcceb",
  colorBlueAvatar: "#4573d2",
  colorMintAvatar: "#00a98c",
  transitionSpeedFast: "0.05s",
  transitionSpeed: "0.1s",
  transitionSpeedSlow: "0.15s",
  spaceMicro: pxToRem(6),
  spaceTiny: pxToRem(10),
  spaceSmall: pxToRem(16),
  spaceBase: pxToRem(24),
  spaceMedium: pxToRem(32),
  spaceLarge: pxToRem(42),
  spaceXLarge: pxToRem(50),
  invisibleTextIndent: "-999999rem",
  hoverOpacity: "0.75",
  disabledOpacity: "0.5",
  navHeightMobile: 60,
  navHeight: 60,
  appNavHeight: 50,
  pageHeaderHeight: 64,
  contentWidth: pxToRem(1140),
  paragraphWidth: pxToRem(460), // ~ 65 characters in 16px
  mobileMinWidth: pxToRem(325),
  mobileMidWidth: pxToRem(480),
  mobileMaxWidth: pxToRem(640),
  mobileTabletMinWidth: pxToRem(641),
  mobileTabletMaxWidth: pxToRem(768),
  tabletMinWidth: pxToRem(769),
  tabletMaxWidth: pxToRem(1080),
  laptopMinWidth: pxToRem(1081),
  laptopMidWidth: pxToRem(1280),
  laptopMaxWidth: pxToRem(1440),
  desktopMinWidth: pxToRem(1441),
  desktopMaxWidth: pxToRem(1920),
  roundness1: pxToRem(3),
  roundness2: pxToRem(5),
  roundness3: pxToRem(8),
  roundness4: pxToRem(10),
  imageRatio1x1: "96.25%",
  imageRatio16x9: "52.941%",
  imageRatioOrg: "38%",
  draggingOpacity: 0.25,
};

export default variables;
