import { css } from "@emotion/react";

const reactMasonry = css`
  .my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    width: auto;
  }
`;

export default reactMasonry;
