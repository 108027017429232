import { css } from "@emotion/react";

import variables from "./variables";
import pxToRem from "./px-to-rem";
import { bodyNormal, hiddenText } from "./typography";

const toastify = css`
  // Toast
  .Toastify .Toastify__toast {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    grid-gap: ${variables.spaceSmall};
    border-radius: ${variables.roundness1};
    padding: ${variables.spaceSmall} ${pxToRem(14)};
    background: ${variables.colorText};
    color: ${variables.colorWhite};
    min-height: auto;
    border-radius: ${pxToRem(5)};

    @media (min-width: ${variables.mobileTabletMaxWidth}) {
      padding: ${variables.spaceSmall} ${pxToRem(26)};
    }
  }

  .ReactModal__Body--open .Toastify .Toastify__toast {
    background: ${variables.colorBackground};
    color: ${variables.colorText};
    border: ${pxToRem(1)} solid ${variables.colorDarkContainerGray};
  }

  .Toastify .Toastify__toast-container {
    width: auto;
    max-width: 90%;
    min-width: ${pxToRem(320)};
  }

  .Toastify .Toastify__toast-body {
    ${bodyNormal};
    display: grid;
    align-items: center;
    grid-auto-flow: column;
    grid-gap: ${pxToRem(12)};
    margin: 0;

    @media (min-width: ${variables.mobileTabletMaxWidth}) {
      grid-gap: ${pxToRem(18)};
    }

    ::before {
      display: block;
      width: ${pxToRem(40)};
      height: ${pxToRem(40)};
      content: "";
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
  }

  .Toastify .Toastify__toast--info .Toastify__toast-body {
    ::before {
      background-image: url("/static/icons/info.svg");
      background-size: ${pxToRem(40)};
    }
  }

  .Toastify .Toastify__toast--success .Toastify__toast-body {
    ::before {
      background-image: url("/static/icons/checkmark.svg");
      background-size: ${pxToRem(40)};
    }
  }

  .Toastify .Toastify__toast--error .Toastify__toast-body {
    ::before {
      background-image: url("/static/icons/warning.svg");
      background-size: ${pxToRem(40)};
    }
  }

  .Toastify .Toastify__close-button {
    ${hiddenText};
    display: block;
    position: relative;
    margin: 0;
    width: ${pxToRem(40)};
    height: ${pxToRem(40)};
    align-self: initial;

    ::before {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: ${pxToRem(24)};
      height: ${pxToRem(24)};
      content: "";
      background-image: url("/static/icons/close.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
  }

  .Toastify__progress-bar {
    background: ${variables.colorMainBlue};
  }
`;

export default toastify;
