/**
 * Determine if the environment is browser
 * @type {boolean}
 */

const isBrowser = (): boolean => {
  return typeof window !== "undefined";
};

export default isBrowser;
