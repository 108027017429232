// https://medium.com/code-better/css-units-for-font-size-px-em-rem-79f7e592bb97

import constants from "./constants";

const pxToRem = (px) => {
  const value = px / constants.baseFontSize;
  return `${value}rem`;
};

export default pxToRem;
