import { css } from "@emotion/react";

import variables from "./variables";

const touchScrollY = css`
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  overflow-x: hidden;
  @media (min-width: ${variables.mobileTabletMaxWidth}) {
    overflow-y: auto;
  }
`;

const touchScrollX = css`
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
  overflow-y: hidden;
  @media (min-width: ${variables.mobileTabletMaxWidth}) {
    overflow-x: auto;
  }
`;

export { touchScrollY, touchScrollX };
